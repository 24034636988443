import ReactRotatingText from 'react-rotating-text';

function AttributeContainer(props) {
    return (
        <div className="attributeContainer">
            <h1 className="subtext">
                i'm <ReactRotatingText items={props.things} />
            </h1>
        </div>
    );
  }
  export default AttributeContainer;