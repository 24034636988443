// import logo from './logo.svg';
import './styles/App.css';
import Title from './components/Title';
import AttributeContainer from './components/AttributeContainer';

function App() {
  return (
    <div className="App">
      <div>
        <Title name = {caroline.name} />
      </div>
      <AttributeContainer things = {caroline.attributes}/>
    </div>
  );
}

const caroline = {
  name:{
    firstName: 'caroline',
    lastName: 'goodrich'
  },
  employer: 'Blue Apron',
  attributes: ['a software engineer.', 'a kite flyer.', 'a cat mom.', 'a new wave enthusiast.', 'an ok cook.' ] 
};

export default App;
